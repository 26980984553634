.search__input.field__input {
  padding-right: 9.8rem;
}

.search__button {
  right: var(--inputs-border-width);
  top: var(--inputs-border-width);
}

.reset__button {
  right: calc(var(--inputs-border-width) + 4.4rem);
  top: var(--inputs-border-width);
}

.reset__button:not(:focus-visible)::after {
  border-right: 0.1rem solid rgba(var(--color-foreground), 0.08);
  display: block;
  height: calc(100% - 1.6rem);
  content: '';
  position: absolute;
  right: 0;
}

.reset__button:not(:focus)::after {
  border-right: 0.1rem solid rgba(var(--color-foreground), 0.08);
  display: block;
  height: calc(100% - 1.8rem);
  content: '';
  position: absolute;
  right: 0;
}

.search__button:focus-visible,
.reset__button:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 4;
}

.search__button:focus,
.reset__button:focus {
  background-color: rgb(var(--color-background));
  z-index: 4;
}

.search__button:not(:focus-visible):not(.focused),
.reset__button:not(:focus-visible):not(.focused) {
  box-shadow: inherit;
  background-color: inherit;
}

.search__button:hover .icon,
.reset__button:hover .icon {
  transform: scale(1.07);
}

.search__button .icon {
  height: 1.8rem;
  width: 1.8rem;
}

.reset__button .icon.icon-close {
  height: 1.8rem;
  width: 1.8rem;
  stroke-width: 0.1rem;
}

/* Remove extra spacing for search inputs in Safari */
input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.template-search__results {
  position: relative;
}
